import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import CatalogFiltersTags from "../catalog-filters-tags/catalog-filters-tags.component";
import { AccountContext } from "../../../context/account.context";
import CatalogMain from "./catalog-main";

import {
  GET_COLLECTIONS,
  GET_ITEMS_WITH_FILTERS,
} from "../../../queries/queries";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";

import { FilterContext } from "../../../context/filterContext";
import "./catalog-main.css";
import useLocationFromSession from "../../../shared/hooks/useLocationFromSession";
import Loader from "../../common/loader/loader.component";

const _ = require("lodash");

const CollectionsOverviewContainer = () => {
  window.scrollTo(0, 0);
  const location = useLocation();
  const { t } = useTranslation();

  const { searchState, setSearchState } = useContext(FilterContext);
  const { accountState, setAccountState } = useContext(AccountContext);

  let { term, facetValueIds, collectionId, sort } = searchState;

  const factValuesIds = facetValueIds.map((fv) => fv.id);
  const collectionIds = collectionId ? collectionId["id"] : null;

  const [state, setState] = useState({
    bottom: false,
  });

  let search = new URLSearchParams(location.search).get("search");
  let category = new URLSearchParams(location.search).get("category");
  let facets = new URLSearchParams(location.search).get("facets");

  const { data: userData, loading: userLoader } = useQuery(GET_ACTIVE_USER);
  const { data: colleactionsData, loading: collectionLoader } =
    useQuery(GET_COLLECTIONS);

  useEffect(() => {
    if (search && search !== "undefined") {
      setSearchState({
        ...searchState,
        term: search,
        previousPage: location?.pathname,
      });
    }
    if (category && category !== "undefined") {
      const col = colleactionsData?.collections?.items.filter(
        (c) => c?.id === category
      );

      if (col && col.length) {
        setSearchState({
          ...searchState,
          facetValueIds: [],
          collectionId: col[0],
          facetFaluesPerCollectionInit: true,
          previousPage: location?.pathname,
        });
      }
    }
    if (facets && facets !== "undefined") {
      // setSearchState({
      //   ...searchState,
      //   facetValueIds: facets,
      //   previousPage: location?.pathname
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, category, facets, colleactionsData]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const { queryLocation, isLocationValid } = useLocationFromSession();
  const { loading, error, data } = useQuery(GET_ITEMS_WITH_FILTERS, {
    // skip: !isLocationValid,
    variables: {
      term,
      facetValueIds: factValuesIds,
      collectionId: collectionIds,
      groupByProduct: !searchState?.term?.trim(),
      sort,
      location: isLocationValid ? queryLocation : null,
    },
  });

  if (error) return `Error! ${error.message}`;

  const items = data?.search?.items;

  if (loading || collectionLoader || userLoader) {
    return (
      <div
        style={{ height: "100vh", display: "flex", justifyContent: "center" }}
      >
        <Loader color={"#B73232"} size={50} />
      </div>
    );
  }

  if (!items?.length) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontFamily: "Barlow",
        }}
      >
        Currently no items available. Please try selecting a different address
      </div>
    );
  }

  return (
    <>
      <CatalogFiltersTags className="catalog-main__back-home-btn" />
      <div className="catalog-main__gap-divider"></div>
      <CatalogMain
        items={items}
        toggleDrawer={toggleDrawer}
        state={state}
        setState={setState}
        loading={loading}
      />
    </>
  );
};

export default CollectionsOverviewContainer;
