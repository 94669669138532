import React from "react";
import Grid from "@material-ui/core/Grid";

import ItemCard from "../../common/item-card/item-card";
import "./catalog-items.css";

function CatalogItems(props) {
  const { collectionId, showItem, items } = props;

  const itemsToDisplay = items.filter((i) =>
    i.collectionIds.includes(collectionId)
  );

  return (
    <Grid
      container
      spacing={window.innerWidth > 900 ? 2 : 1}
      style={{ marginBottom: 32 }}
    >
      {itemsToDisplay.slice(0, 4).map((item, i) => {
        const price = item.priceWithTax.min || item.priceWithTax.value;
        return (
          <Grid item key={i}>
            <ItemCard
              key={i}
              itemCard={item}
              id={item.productVariantId}
              productVariantName={item.productVariantName}
              title={item?.merchantName}
              img={item?.productAsset?.preview}
              description={item.productName}
              weight={item?.productVariantPriceMeasurement}
              price={price}
              currencyCode={item?.currencyCode}
              merchantLogo={item?.merchantAsset?.preview}
              showItem={() => {
                showItem(item?.slug);
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default CatalogItems;
