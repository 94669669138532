import { useQuery } from "@apollo/client";
import React, { useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import HomeCarousel from "../home/home-carousel/home-carousel.component";
import { GET_ITEMS_WITH_FILTERS } from "../../queries/queries";
import Breadcrumbs from "../common/breadcrumbs/breadcrumbs.component";
import SupplierPageDetails from "./supplier-page-details/supplier-page-details.component";
import SupplierPageProducts from "./supplier-page-products/supplier-page-products.component";
import { ReactComponent as BreadCrumbsArrow } from "../../assets/rightArrow.svg";
import { ReactComponent as BreadCrumbsArrowLeft } from "../../assets/leftArrow.svg";
import NoResultsPage from "../common/no -results-page/no-results-page.component";
import Loader from "../common/loader/loader.component";
import { useTranslation } from "react-i18next";
import { AccountContext } from "../../context/account.context";
import SupplierPageBreadcrumbs from "./supplier-page-breadcrumbs/supplier-page-breadcrumbs.jsx";
import useLocationFromSession from "../../shared/hooks/useLocationFromSession";

const _ = require("lodash");

const SupplierPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const dir = t("css.side");
  window.scrollTo(0, 0);

  const { queryLocation, isLocationValid } = useLocationFromSession();
  const { loading, error, data } = useQuery(GET_ITEMS_WITH_FILTERS, {
    // skip: !isLocationValid,
    variables: {
      term: "",
      facetValueIds: [],
      collectionId: null,
      merchantSlug: id,
      groupByProduct: true,
      location: isLocationValid ? queryLocation : null,
    },
  });

  const [showCarousel, setShowCarousel] = useState(false);
  const { accountState } = useContext(AccountContext);

  React.useEffect(() => {
    const historyArr = history.location.pathname.split("/");
    setShowCarousel(
      historyArr[historyArr.length - 1] === accountState?.sharedData?.ALIM_LTD
    );
  }, [accountState]);

  if (loading) {
    return (
      <div style={{ height: "100vh" }}>
        <Loader color={"#B73232"} size={50} />
      </div>
    );
  }
  if (error) return `Error! ${error.message}`;

  let items = data?.search?.items;

  const onGoBack = () => {
    history.goBack();
  };

  const showItem = (x) => {
    history.push(`/item/${x}`);
  };

  if (items?.length === 0) {
    return (
      <NoResultsPage
        title={"Something went wrong"}
        descriprion={"Try another supplier"}
        goBack={onGoBack}
      />
    );
  }

  const temp = [].concat.apply(
    [],
    items?.map((i) => i?.facetIds)
  );

  let relevatFilters = data?.search?.facetValues?.filter((v) => {
    const facetId = v?.facetValue?.facet?.id;

    return temp.some((x) => x === facetId);
  });

  const filters = _.groupBy(relevatFilters, "facetValue.facet.name");
  const merchant = {
    name: items ? items[0]?.merchantName : null,
    preview: items ? items[0]?.merchantAsset?.preview : null,
  };

  return (
    <React.Fragment>
      <div style={{ paddingTop: 120 }}></div>
      <div style={{ display: "block" }}>
        <SupplierPageBreadcrumbs merchant={merchant} />
        {/* <Breadcrumbs prevPage="ourSuppliers" onGoBack={onGoBack}>
          {dir === "ltr" ? (
            <BreadCrumbsArrow style={{ margin: "0 16px 0 16px" }} />
          ) : (
            <BreadCrumbsArrowLeft style={{ margin: "0 16px 0 16px" }} />
          )}
          <span className="h6-regular">{merchant?.name}</span>
        </Breadcrumbs> */}
      </div>

      <SupplierPageDetails merchant={merchant} />
      {showCarousel && <HomeCarousel />}
      <SupplierPageProducts
        showItem={showItem}
        items={items}
        filters={filters}
      />
    </React.Fragment>
  );
};

export default SupplierPage;
