import React from "react";
import { h4Medium, h6Regular } from "../../../shared/constants";
import CartItem from "../../cart/cart-item/cart-item.component";
import { formatDeliveryDate } from "./Parcel.helper";

interface ParcelProps {
  number: number;
  availableDelivery: { from: string; to: string };
  parcelLines: any[];
  isoDate: string;
  startTime: string;
  endTime: string;
  isMoreThanOneParcel: boolean;
  children?: React.ReactNode;
}

const Parcel: React.FC<ParcelProps> = ({
  number,
  children,
  parcelLines,
  isoDate,
  startTime,
  endTime,
  isMoreThanOneParcel,
}) => {
  return (
    <div style={{ marginTop: 40 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
          paddingBottom: 40,
        }}
      >
        {isMoreThanOneParcel ? (
          <span style={{ ...(h4Medium as any) }}>Parcel no. {number}</span>
        ) : null}
        <span style={{ ...(h6Regular as any) }}>
          {formatDeliveryDate(isoDate, startTime, endTime)}
        </span>
      </div>
      {children}
      <div
        style={{
          borderTop: "1px dashed #15222F1A",
          marginTop: children ? 32 : 0,
        }}
      >
        {parcelLines?.map((item) => (
          <CartItem
            key={item.id}
            item={item}
            onCartItemClicked={(item: any) => {}}
          />
        ))}
      </div>
    </div>
  );
};

export default Parcel;
