import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useQuery } from "@apollo/client";

import FormInput from "../../common/form-input/form-input.component";
import Checkbox from "../../common/checkbox/checkbox";
import SearchLocationInput from "../../common/places-autocomplete/places-autocomplete.component";
import { h5Medium, h6Regular } from "../../../shared/constants";
import TextAreaComponent from "../../common/text-area/text-area.component";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";

const AddressForm = ({
  addressDetails,
  setAddressDetails,
  setIsVaildInput,
}) => {
  const { t } = useTranslation();
  const dir = t("css.side");

  const [selectedValue, setSelectedValue] = useState(0);
  const [phoneValue, setPhoneValue] = useState("");

  const [addressValues, setAddressValues] = useState({
    city: "",
    province: "",
    postalCode: "",
    streetLine1: "",
  });

  const { data: userData } = useQuery(GET_ACTIVE_USER);

  useEffect(() => {
    setAddressDetails({
      ...addressDetails,
      ...addressValues,
      phoneNumber: userData?.activeCustomer?.phoneNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    setAddressDetails({
      ...addressDetails,
      ...addressValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressValues]);

  useEffect(() => {
    setAddressDetails({
      ...addressDetails,
      ...addressValues,
      phoneNumber:
        selectedValue === 0
          ? userData?.activeCustomer?.phoneNumber
          : phoneValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneValue]);

  const controlProps = (idx) => ({
    checked: idx === selectedValue,
    onChange: (e) => {
      setSelectedValue(idx);
      setAddressDetails({
        ...addressDetails,
        ...addressValues,
        phoneNumber:
          idx === 0 ? userData?.activeCustomer?.phoneNumber : phoneValue,
      });
    },
    value: idx === 0 ? userData?.activeCustomer?.phoneNumber : phoneValue,
    name: "phoneNumber",
    inputProps: { "aria-label": "" },
  });

  return (
    <React.Fragment>
      <div>
        <SearchLocationInput
          setAddressValues={setAddressValues}
          setIsVaildInput={setIsVaildInput}
          styles={{
            margin: "34px 0px 0px 0px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        />
      </div>

      <FormInput
        name="streetLine2"
        type="string"
        value={addressDetails.streetLine2}
        handleChange={(e) => {
          setAddressDetails({
            ...addressDetails,
            ...addressValues,
            streetLine2: e.target.value,
          });
        }}
        label={t("account.apartment")}
        border="1px solid rgba(0, 0, 0, 0.1)"
        margin={"54px 0px 0px 0px"}
      />

      <div style={{ marginBottom: 32 }}>
        <TextAreaComponent
          label={t("checkout.comments")}
          name="comments"
          type="string"
          handleChange={(e) => {
            setAddressDetails({
              ...addressDetails,
              ...addressValues,
              customFields: {
                comments: e.target.value,
              },
            });
          }}
          value={addressDetails?.customFields?.comments}
          margin={"54px 0px 0px 0px"}
        />
      </div>

      <span style={{ ...h5Medium, marginTop: 32 }}>
        {t("account.addressPhoneNumber")}
      </span>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: 360,
          marginTop: 24,
        }}
      >
        {userData?.activeCustomer?.phoneNumber && (
          <div style={{ display: "flex", alignItems: "center", ...h6Regular }}>
            <Radio {...controlProps(0)} color="success" />
            <span style={{ direction: "ltr" }}>
              {userData?.activeCustomer?.phoneNumber}
            </span>
          </div>
        )}

        {/* <div style={{ display: "flex", alignItems: "center", ...h6Regular }}>
          <Radio {...controlProps(1)} color="success" />
          <PhoneInput
            style={{
              ...h6Regular,
              direction: "ltr",
            }}
            defaultCountry="IL"
            withCountryCallingCode={true}
            international={true}
            placeholder={t("checkout.enterPhoneNumber")}
            value={phoneValue}
            onChange={setPhoneValue}
          />
        </div> */}
      </div>

      <div style={{ marginTop: 24 }}></div>

      <Checkbox
        value={t("account.makeItDefaultAddress")}
        updateFacetsValues={(e) => {
          setAddressDetails({
            ...addressDetails,
            ...addressValues,
            defaultShippingAddress: true,
            defaultBillingAddress: true,
            defaultAddress: true,
          });
        }}
        removeFacetsValues={(e) => {
          setAddressDetails({
            ...addressDetails,
            ...addressValues,
            defaultShippingAddress: false,
            defaultBillingAddress: false,
            defaultAddress: false,
          });
        }}
        facetValue={false}
      />
    </React.Fragment>
  );
};

export default AddressForm;
