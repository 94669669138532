import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";

import { h6Medium, h6Regular, pointer } from "../../../shared/constants";

import { Backdrop, Fade, Modal } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";
import Loader from "../../common/loader/loader.component";
import AddAddress from "../add-address/add-address.component";
import Checkbox from "../../common/checkbox/checkbox";
import { useTranslation } from "react-i18next";
import {
  UPDATE_ADDRESS,
  UPDATE_FULL_ADDRESS,
} from "../../../queries/user.queries";
import { FireworkSpinner } from "react-spinners-kit";
import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";
import SearchLocationInput from "../../common/places-autocomplete/places-autocomplete.component";
import PhoneInput from "react-phone-number-input";
import VerifyNumberContainer from "../../common/verify-number/verify-number-container.component";
import AccountEditAddress from "../account-edit-address/account-edit-address.jsx";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  title: {
    marginBottom: 40,
  },
  address: {
    width: 464,
    height: "auto",
    borderRadius: 16,
    background: "#FFFFFF",
    padding: 40,
    marginBottom: 32,
    position: "relative",

    "@media (max-width:940px)": {
      width: "100%",
      marginBottom: 16,
    },
  },
  addressBody: {
    ...h6Regular,
    display: "flex",
    justifyContent: "space-between",
  },
  addressFooter: {
    ...h6Regular,
    width: 300,
    marginTop: 26,
    display: "flex",
  },
  checkbox: {
    background: "#80BB34",
    borderRadius: 6,
  },
  reg: {
    "@media (max-width:940px)": {
      display: "none",
    },
  },
  res: {
    "@media (min-width:940px)": {
      display: "none",
    },
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  editModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const AccountAddresses = () => {
  window.scrollTo(0, 0);
  const classes = useStyles();
  const { t } = useTranslation();

  const { loading, error, data } = useQuery(GET_ACTIVE_USER);

  const [activeUser, setActiveUser] = useState({
    addresses: [],
  });

  useEffect(() => {
    if (data && data.activeCustomer) {
      const { addresses } = data.activeCustomer;
      setActiveUser({
        addresses,
      });
    }
  }, [data]);

  if (loading) return <Loader color={"#B73232"} size={70} />;
  if (error) {
    console.log(error);
  }

  const { addresses } = activeUser;
  const userPhoneNumber = data?.activeCustomer?.phoneNumber;
  const hasMultipleAddresses = activeUser?.addresses?.length > 1;

  return (
    <React.Fragment>
      <div className={`${classes.title}`}>
        <span className={`h2 ${classes.reg}`}>{t("account.Addresses")}</span>
        <div className={classes.res}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontWhight: "bold",
              marginBottom: 16,
            }}
          >
            <span className="h3">{t("account.Addresses")}</span>
            <AddAddress />
          </div>
        </div>
      </div>
      <div className={classes.res}>
        {data?.activeCustomer?.addresses?.length ? (
          <div className={classes.container}>
            {addresses.map((address, i) => (
              <Address
                address={address}
                key={i}
                phone={userPhoneNumber}
                hasMultipleAddresses={hasMultipleAddresses}
              />
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={classes.reg}>
        {data?.activeCustomer?.addresses?.length ? (
          <div className={classes.container}>
            {addresses.map((address, i) => (
              <Address address={address} key={i} phone={userPhoneNumber} />
            ))}
            <AddAddress />
          </div>
        ) : (
          <AddAddress />
        )}
      </div>
    </React.Fragment>
  );
};

const Address = ({ address, phone, hasMultipleAddresses }) => {
  const classes = useStyles();
  const {
    fullName,
    streetLine1,
    city,
    postalCode,
    country,
    phoneNumber,
    defaultShippingAddress,
  } = address;

  const [addressUpdated, setAddressUpdated] = useState({
    fullName,
    streetLine1,
    city,
    postalCode,
    country,
    phoneNumber,
    defaultShippingAddress,
  });

  const [editMode, setEditMode] = useState(false);
  const [selectedValue, setSelectedValue] = useState(0);
  const [phoneValue, setPhoneValue] = useState(phone);
  const [open, setOpen] = useState(false);

  const [addressValues, setAddressValues] = useState({
    streetLine1: address?.streetLine1 || "",
  });

  const [isVaildInput, setIsVaildInput] = useState(false);

  let [updateAddress, { loading: updateAddressLoading }] = useMutation(
    UPDATE_ADDRESS,
    {
      variables: {
        id: address?.id,
        defaultShippingAddress: true,
      },
      refetchQueries: [
        { query: GET_ACTIVE_USER }, // Query name
      ],
      awaitRefetchQueries: true,
    }
  );

  let [updateFullAddress, { loading: updateFullAddressLoading }] = useMutation(
    UPDATE_FULL_ADDRESS,
    {
      variables: {
        id: address?.id,
        fullName: addressUpdated.fullName,
        streetLine1: addressValues.streetLine1,
        phoneNumber: phoneValue,
      },
      refetchQueries: [
        { query: GET_ACTIVE_USER }, // Query name
      ],
    }
  );

  const controlProps = (idx, phoneNum) => ({
    checked: idx === selectedValue,
    onChange: (e) => {
      setSelectedValue(idx);
      setPhoneValue(phoneNum);
    },
    value: idx === 0 ? phone : phoneNumber,
    name: "phoneNumber",
    inputProps: { "aria-label": "" },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onVerify = async (bool = true) => {
    if (!bool) return;
    setOpen(false);

    try {
      const data = await updateFullAddress();
      if (data) {
        setEditMode(false);
      }
    } catch (err) {
      alert(err);
    }
  };

  const { t } = useTranslation();

  const bodyNonEdit = (
    <div className={classes.addressBody}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          ...h6Regular,
        }}
      >
        <span>{streetLine1}</span>
        <span
          style={{
            direction: "ltr",
            textAlign: t("css.side") === "rtl" ? "end" : "start",
          }}
        >
          {phoneNumber}
        </span>
      </div>

      <span
        style={{
          ...h6Medium,
          color: "#80BB34",
          ...pointer,
        }}
        onClick={() => {
          setEditMode(true);
        }}
      >
        {t("common.edit")}
      </span>
    </div>
  );

  const loaderContainer = (
    <div className={classes.address}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          margin: "0 auto",
        }}
      >
        <FireworkSpinner color={"#B73232"} size={70} loading={true} />
      </div>
    </div>
  );

  const addressContainer = (
    <div
      className={classes.address}
      style={{ filter: updateFullAddressLoading ? "blur(3px)" : "none" }}
    >
      {bodyNonEdit}

      <div className={classes.addressFooter}>
        {updateAddressLoading ? (
          <FireworkSpinner color={"#B73232"} size={15} loading={true} />
        ) : (
          <React.Fragment>
            <Checkbox
              updateFacetsValues={async () => {
                await updateAddress();
              }}
              removeFacetsValues={(e) => {}}
              isChecked={defaultShippingAddress ? true : false}
            />
            <span>
              {defaultShippingAddress
                ? t("account.defaultAddress")
                : t("account.makeItDefaultAddress")}
            </span>
          </React.Fragment>
        )}
      </div>
    </div>
  );

  const handleEditModalClose = () => setEditMode(false);

  return updateFullAddressLoading ? (
    loaderContainer
  ) : (
    <React.Fragment>
      {addressContainer}
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <VerifyNumberContainer
            handleClose={handleClose}
            onVerify={onVerify}
            phoneNumber={phoneValue}
            setPhoneValue={(e) => {
              setPhoneValue(e);
            }}
          />
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={editMode}
        onClose={handleEditModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editMode}>
          <AccountEditAddress
            address={address}
            phone={phone}
            setEditMode={setEditMode}
            hasMultipleAddresses={hasMultipleAddresses}
          />
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

export default AccountAddresses;
