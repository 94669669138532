import { format, isToday, isTomorrow } from "date-fns";

const useFormatedDate = () => {
  const getFormattedDate = (date) => {
    const deliveryDate = new Date(date);
    if (isToday(deliveryDate)) {
      return `Today, ${format(deliveryDate, "MMM d")}`;
    } else if (isTomorrow(deliveryDate)) {
      return `Tomorrow, ${format(deliveryDate, "MMM d")}`;
    } else {
      return format(deliveryDate, "EEEE, MMM d");
    }
  };

  return { getFormattedDate };
};

export default useFormatedDate;
