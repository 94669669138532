import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";
import Radio from "@mui/material/Radio";
import { makeStyles } from "@material-ui/core";

import { ReactComponent as StarIcon } from "../../../assets/star.svg";
import { h5Medium, h6Regular } from "../../../shared/constants";
import { useTranslation } from "react-i18next";
import { GET_ACTIVE_ORDER } from "../../../queries/cart.queries";

const useStyles = makeStyles({
  addressDetails: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
  },

  addressDetailsBody: {
    display: "flex",
    flexDirection: "column",
  },
});

const SavedAddresess = ({ setAddressForShipping }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [chosenAddress, setChosenAddress] = useState(null);

  const { data: userData } = useQuery(GET_ACTIVE_USER);
  const { data: orderData } = useQuery(GET_ACTIVE_ORDER);

  const currentOrderAddress = orderData?.activeOrder?.shippingAddress;

  const setStateForShipping = (item) => {
    setAddressForShipping({
      fullName: userData?.activeCustomer?.firstName,
      streetLine1: item?.streetLine1,
      streetLine2: item?.streetLine2,
      city: item?.streetLine2,
      postalCode: item?.postalCode,
      countryCode: "IL",
      phoneNumber: item?.phoneNumber,
      defaultShippingAddress: item?.defaultShippingAddress,
      defaultBillingAddress: item?.defaultBillingAddress,
      idForUpdate: item.id,
    });
  };

  useEffect(() => {
    if (userData) {
      const availableAddresses = userData?.activeCustomer?.addresses;
      let shippingAdress = availableAddresses?.find(
        (a) => a.defaultShippingAddress
      );

      if (currentOrderAddress) {
        const { latitude, longitude } = currentOrderAddress;
        shippingAdress = availableAddresses?.find(
          (address) => address.latitude === latitude && address.longitude === longitude
        );
      }

      if (shippingAdress && shippingAdress?.id) {
        setChosenAddress(shippingAdress.id);
        setStateForShipping(shippingAdress);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, currentOrderAddress]);

  const controlProps = (idx, item) => ({
    checked: chosenAddress === idx,
    onChange: () => {
      setChosenAddress(idx);
      setStateForShipping(item);
    },
    value: idx,
    name: "address-chosen",
    inputProps: { "aria-label": idx },
  });

  return (
    <div>
      {userData?.activeCustomer?.addresses?.length ? (
        userData?.activeCustomer?.addresses?.map((a) => (
          <div key={a.id} className={classes.addressDetails}>
            <Radio {...controlProps(a.id, a)} color="success" />
            {a.defaultShippingAddress === true && (
              <div style={{ marginRight: 8 }}>
                <StarIcon />
              </div>
            )}
            <div className={classes.addressDetailsBody}>
              <span style={{ ...h6Regular, opacity: 0.8 }}>
                {a.streetLine1}
              </span>
            </div>
          </div>
        ))
      ) : (
        <span
          style={{
            ...h5Medium,
            opacity: 0.8,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {t("account.noAdresses")}
        </span>
      )}
    </div>
  );
};

export default SavedAddresess;
