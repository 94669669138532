import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import { useMutation } from "@apollo/client";

import {
  h4Medium,
  pointer,
  h5Medium,
  h6Regular,
  h6Medium,
} from "../../../shared/constants";
import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";
import { ReactComponent as TrashIcon } from "./trash.svg";
import SearchLocationInput from "../../common/places-autocomplete/places-autocomplete.component";
import FormInput from "../../common/form-input/form-input.component";
import TextAreaComponent from "../../common/text-area/text-area.component";
import Checkbox from "../../common/checkbox/checkbox";
import { UPDATE_FULL_ADDRESS } from "../../../queries/user.queries";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";
import Loader from "../../common/loader/loader.component";
import { DELETE_ADDRESS } from "../../../mutations/delete-address.mutation";

const useStyles = makeStyles({
  editAddressModalContent: {
    background: "#FFF",
    padding: 48,
    width: 544,
    overflow: "scroll",
    maxHeight: "100%",
    borderRadius: 32,
  },
  editAddressModalHeader: {
    ...h4Medium,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    height: 56,
    width: 320,
    border: "none",
    textAlign: "center",
    background: "#80BB34",
    borderRadius: 16,
    ...h5Medium,
    color: "#FFFFFF",
    ...pointer,
  },
});

const AccountEditAddress = ({
  address,
  phone,
  setEditMode,
  hasMultipleAddresses,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [addressValues, setAddressValues] = useState(address);

  const [isVaildInput, setIsVaildInput] = useState(false);

  let [updateFullAddress, { loading: updateFullAddressLoading }] = useMutation(
    UPDATE_FULL_ADDRESS,
    {
      variables: {
        id: address?.id,
        ...addressValues,
        defaultShippingAddress: addressValues?.defaultShippingAddress,
        defaultBillingAddress: addressValues?.defaultBillingAddress,
      },
      refetchQueries: [{ query: GET_ACTIVE_USER }],
    }
  );

  let [deleteAddress, { loading: deleteAddressLoading }] = useMutation(
    DELETE_ADDRESS,
    {
      variables: {
        id: address?.id,
      },
      refetchQueries: [
        { query: GET_ACTIVE_USER }, // Query name
      ],
    }
  );

  const onSave = async () => {
    if (!addressValues?.streetLine1) return;

    const data = await updateFullAddress();

    const { latitude, longitude } = addressValues;

    if (!hasMultipleAddresses) {
      sessionStorage.setItem("longitude", longitude);
      sessionStorage.setItem("latitude", latitude);
    }

    if (data) {
      setEditMode(false);
    }
  };

  const onDelete = async () => {
    try {
      await deleteAddress();
      if (!hasMultipleAddresses) {
        sessionStorage.removeItem("latitude");
        sessionStorage.removeItem("longitude");
      }
      setEditMode(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.editAddressModalContent}>
      <div className={classes.editAddressModalHeader}>
        <span style={{ opacity: 0.8 }}>{t("account.editDeliveryAddress")}</span>
        <RemoveIcon
          style={{ height: 24, width: 24, ...pointer }}
          onClick={() => setEditMode(false)}
        />
      </div>
      <div style={{ margin: "32px 0px 10px 0px" }}>
        <SearchLocationInput
          styles={{ margin: "32px 0px 10px 0px" }}
          setAddressValues={setAddressValues}
          setIsVaildInput={setIsVaildInput}
          value={address?.streetLine1}
        />
      </div>
      <FormInput
        name="streetLine2"
        type="string"
        value={addressValues.streetLine2}
        handleChange={(e) => {
          setAddressValues({
            ...addressValues,
            streetLine2: e.target.value,
          });
        }}
        label={t("account.apartment")}
        border="2px solid rgba(21, 34, 47, 0.08)"
        margin={"42px 0px 0px 0px"}
        maxWidth={"310px"}
      />
      <div style={{ marginBottom: 52 }}>
        <TextAreaComponent
          label={t("checkout.comments")}
          name="comments"
          type="string"
          handleChange={(e) => {
            setAddressValues({
              ...addressValues,
              customFields: {
                comments: e.target.value,
              },
            });
          }}
          value={addressValues?.customFields?.comments}
          margin={"42px 0px 0px 0px"}
        />
      </div>
      <span style={{ ...h5Medium }}>{t("account.addressPhoneNumber")}</span>
      <div style={{ padding: "26px 0 44px 0" }}>
        <Radio checked color="success" />
        <span style={{ direction: "ltr", ...h6Regular }}>{phone}</span>
      </div>

      <Checkbox
        value={t("account.makeItDefaultAddress")}
        updateFacetsValues={(e) => {
          setAddressValues({
            ...addressValues,
            defaultShippingAddress: true,
            defaultBillingAddress: true,
          });
        }}
        removeFacetsValues={(e) => {
          setAddressValues({
            ...addressValues,
            defaultShippingAddress: false,
            defaultBillingAddress: false,
          });
        }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 24,
          gap: 80,
        }}
      >
        <button
          className={classes.button}
          onClick={onSave}
          disabled={!isVaildInput}
        >
          {updateFullAddressLoading ? (
            <Loader color={"#B73232"} className={classes.button} />
          ) : (
            t("common.Save")
          )}
        </button>
        <div
          style={{ display: "flex", alignItems: "center", gap: 8, ...pointer }}
          onClick={onDelete}
        >
          <div style={{ display: "flex", width: 144, alignItems: "center" }}>
            {deleteAddressLoading ? (
              <Loader color={"#B73232"} />
            ) : (
              <>
                <TrashIcon />
                <span
                  style={{
                    ...h6Medium,
                    color: "#F50050",
                    whiteSpace: "nowrap",
                  }}
                >
                  {t("account.deleteAddress")}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountEditAddress;
