import { useState, useEffect } from "react";

const useLocationFromSession = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [isLocationValid, setIsLocationValid] = useState(false);

  useEffect(() => {
    const latitude = parseFloat(sessionStorage.getItem("latitude"));
    const longitude = parseFloat(sessionStorage.getItem("longitude"));

    if (!isNaN(latitude) && !isNaN(longitude)) {
      setLocation({ latitude, longitude });
      setIsLocationValid(true);
    } else {
      setIsLocationValid(false);
    }
  }, []);

  return { queryLocation: location, isLocationValid };
};

export default useLocationFromSession;
