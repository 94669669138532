import React, { useState, useEffect, useContext } from "react";

import { ReactComponent as PlusIcon } from "../../../assets/plus.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";

import "./item-page-add-button.component.css";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_TO_CART,
  GET_ACTIVE_ORDER,
  GET_TOTAL_LINES,
  REMOVE_FROM_CART,
  REMOVE_ORDER_LINE,
} from "../../../queries/cart.queries";
import Loader from "../../common/loader/loader.component";
import { MOVE_STATE } from "../../../queries/checkout.queries";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";
import { AccountContext } from "../../../context/account.context";
import useLocationFromSession from "../../../shared/hooks/useLocationFromSession";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ItemPageAddButton = ({ item, preview }) => {
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [itemRemoved, setItemRemoved] = useState(false);
  const [amountValue, setAmountValue] = useState(0);
  const [orderLine, setOrderLine] = useState(null);
  const [inputValue, setInputValue] = useState(false);
  const [snackAction, setSnackAction] = useState("");
  const { accountState, setAccountState } = useContext(AccountContext);
  const { isLocationValid } = useLocationFromSession();

  const { isAddress } = accountState;

  const { t } = useTranslation();

  const { loading: orderLoading, data: activeOrder } =
    useQuery(GET_ACTIVE_ORDER);

  const { data: userData } = useQuery(GET_ACTIVE_USER);

  const getCurrentQty = (activeOrder) => {
    const { lines } = activeOrder.activeOrder;

    if (!lines.length) return 0;

    const [active] = lines.filter(
      (line) => line.productVariant.id === item?.id
    );

    if (!active) return 0;
    return active?.quantity;
  };

  useEffect(() => {
    let currentQty = activeOrder?.activeOrder?.lines?.length
      ? getCurrentQty(activeOrder)
      : 0;
    setAmountValue(currentQty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, activeOrder]);

  const [onAddToCartClicked, { data: addedClicked, loading: addedLoading }] =
    useMutation(ADD_TO_CART, {
      variables: {
        variantId: item?.id,
        qty: inputValue ? amountValue : 1,
      },
      refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
      onCompleted: (data) => {
        setInputValue(false);
        if (data?.addItemToOrder?.errorCode) {
          setItemRemoved(true);
          setSnackAction(data?.addItemToOrder?.message);
          setIsSnackOpen(false);
        } else {
          setItemRemoved(false);
          setSnackAction(t("itemPage.added"));
          setIsSnackOpen(false);
        }
      },
    });

  const [moveStateToAddingItems] = useMutation(MOVE_STATE, {
    variables: {
      state: "AddingItems",
    },
    refetchQueries: [{ query: GET_ACTIVE_USER }],
    onCompleted: async () => await onAddToCartClicked(),
  });

  const [removeOrderLine, { loading: lineRemovedLoading }] = useMutation(
    REMOVE_ORDER_LINE,
    {
      variables: {
        id: orderLine?.id,
      },
      refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
      onCompleted: () => {
        setAmountValue(0);
        setItemRemoved(true);
        setSnackAction(t("itemPage.removed"));
        setIsSnackOpen(false);
      },
    }
  );

  const [adjustOrderLine, { loading: adjustLineLoading }] = useMutation(
    REMOVE_FROM_CART,
    {
      variables: {
        id: orderLine?.id,
        qty: amountValue,
      },
      refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
      onCompleted: () => {
        setItemRemoved(amountValue === 0 ? true : false);
      },
    }
  );

  useEffect(() => {
    if (activeOrder) {
      const lines = activeOrder?.activeOrder?.lines;

      if (!lines?.length) return;

      const [active] = lines.filter(
        (line) => line.productVariant.id === item?.id
      );
      setOrderLine(active);
      // setAmountValue(active ? active?.quantity : 0);
    }
  }, [activeOrder, item]);

  if (orderLoading) {
    return <Loader color={"#B73232"} size={70} />;
  }

  const onEditCartAmount = async (bol) => {
    if (!item) return;

    if (isNaN(amountValue)) return;

    if (
      isAddress === null &&
      userData?.activeCustomer === null &&
      !isLocationValid
    ) {
      setAccountState({
        ...accountState,
        isAddress: null,
        isDeliveryModalAddress: true,
      });
      return;
    }

    let qty = activeOrder?.activeOrder?.lines?.length
      ? getCurrentQty(activeOrder)
      : 0;

    if (bol) {
      await addItemToOrder();
      if (addedClicked) {
        setAmountValue(+qty + 1);
      }
    } else {
      if (amountValue <= 0) return;
      if (!activeOrder?.activeOrder?.lines?.length) return;
      if (amountValue > qty) return;
      await removeOrderLine();
    }
  };

  const addItemToOrder = async () => {
    if (
      !activeOrder?.activeOrder ||
      activeOrder?.activeOrder?.state === "AddingItems"
    ) {
      await onAddToCartClicked();
    }
    if (activeOrder?.activeOrder?.state === "ArrangingPayment" ||
      activeOrder?.activeOrder?.state === "Open") {
      await moveStateToAddingItems();
    }
  };

  const onCloseSnack = (bool) => {
    setIsSnackOpen(bool);
  };

  const changeOrderQty = async (qty) => {
    if (isNaN(qty)) return;
    if (qty === 0) {
      await removeOrderLine();
      return;
    }
    if (qty !== 0 && amountValue === 0) {
      await setInputValue(true);
      await setAmountValue(+qty);
      await onAddToCartClicked();
      return;
    }
    if (qty !== 0 && amountValue !== 0) {
      if (!inputValue) {
        await setAmountValue(+qty);
        await adjustOrderLine();
        return;
      } else {
        if (addedClicked) {
          await setAmountValue(+qty);
          await adjustOrderLine();
        }
      }
    }
  };

  return (
    <React.Fragment>
      {amountValue > 0 ? (
        <button className="IPAB__actions--button">
          {addedLoading || lineRemovedLoading || adjustLineLoading ? (
            <Loader color={"#B73232"} size={18} />
          ) : (
            <DeleteIcon
              className="pointer"
              onClick={() => onEditCartAmount(false)}
            />
          )}
          <input
            className="h5-medium IPAB__items-amount"
            type="number"
            min="0"
            max="99"
            value={amountValue}
            onChange={(e) => {
              if (isNaN(e.target.value)) return;
              changeOrderQty(e.target.valueAsNumber);
            }}
          />
          {addedLoading || lineRemovedLoading || adjustLineLoading ? (
            <Loader color={"#B73232"} size={18} />
          ) : (
            <PlusIcon
              className="pointer"
              onClick={() => onEditCartAmount(true)}
            />
          )}
        </button>
      ) : (
        <button
          className="IPAB-button green h5-medium"
          onClick={() => onEditCartAmount(true)}
        >
          {addedLoading ? (
            <Loader color={"#B73232"} size={30} />
          ) : (
            t("itemPage.Add to Basket")
          )}
        </button>
      )}
      <Snackbar
        open={isSnackOpen}
        autoHideDuration={1000}
        onClose={() => onCloseSnack(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: `${t("css.snackBarPosition")}`,
        }}
      >
        <Alert
          onClose={() => onCloseSnack(false)}
          severity={itemRemoved ? "error" : "success"}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={preview}
              alt={item?.name}
              style={{
                height: 50,
                width: 50,
                borderRadius: 5,
                marginRight: 15,
              }}
            />
            <span>
              {t("itemPage.ItemEditMessage", {
                action: snackAction
              })}
            </span>
          </div>
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default ItemPageAddButton;
