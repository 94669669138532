import React from "react";
import { h6Medium } from "../../constants";

interface TagProps {
  text: string;
  color: string;
  backgroundColor: string;
  onClick?: () => void;
}

const Tag: React.FC<TagProps> = ({ text, backgroundColor, color, onClick }) => {
  return (
    <span
      onClick={onClick}
      style={{
        display: "inline-flex",
        padding: "2px 8px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 12,
        backgroundColor,
        color,
        cursor: onClick ? "pointer" : "default",
        ...(h6Medium as any),
      }}
    >
      {text}
    </span>
  );
};

export default Tag;
