import { useQuery } from "@apollo/client";
import { GET_ITEMS_WITH_FILTERS } from "../../../queries/queries";
import ItemCard from "../../common/item-card/item-card";
import React from "react";

import { Grid } from "@material-ui/core";
import Loader from "../../common/loader/loader.component";
import { useHistory } from "react-router";
import useLocationFromSession from "../../../shared/hooks/useLocationFromSession";

const HomeProductsRes = ({ collectionId, cName }) => {
  const history = useHistory();
  const showItem = (x) => {
    history.push(`/item/${x}`);
  };

  const { queryLocation, isLocationValid } = useLocationFromSession();
  const { loading, data } = useQuery(GET_ITEMS_WITH_FILTERS, {
    // skip: !isLocationValid,
    variables: {
      collectionId,
      take: 10,
      skip: 0,
      groupByProduct: true,
      location: isLocationValid ? queryLocation : null,
    },
  });

  if (loading) return <Loader color={"#B73232"} size={50} />;

  const productsArr = data?.search.items;

  const showProductsMobile = productsArr?.slice(0, 4);

  return (
    <HomeProductsMobile
      cName={cName}
      showProductsMobile={showProductsMobile}
      showItem={showItem}
    />
  );
};

const HomeProductsMobile = ({ showProductsMobile, cName, showItem }) => (
  <Grid container spacing={1} className={`${cName}`} justify="center">
    {showProductsMobile?.map((item, i) => {
      const price =
        item.priceWithTax.min || (item.priceWithTax.value / 100).toFixed(2);
      return (
        <Grid item key={i}>
          <ItemCard
            itemCard={item}
            id={item?.productVariantId}
            productVariantName={item?.productVariantName}
            key={i}
            title={item?.merchantName}
            img={item?.productAsset?.preview}
            description={item?.productName}
            weight={item?.productVariantPriceMeasurement}
            price={price}
            currencyCode={item?.currencyCode}
            merchantLogo={item?.merchantAsset?.preview}
            showItem={() => {
              showItem(item.slug);
            }}
          />
        </Grid>
      );
    })}
  </Grid>
);

export default HomeProductsRes;
