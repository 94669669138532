import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import ItemCard from "../../common/item-card/item-card";

import "./supplier-page-products.component.css";

const SupplierPageProducts = ({ items, filters, showItem }) => {
  const keys = Object.keys(filters);
  keys.unshift("All");

  const [isActive, setIsActive] = useState(0);
  const [itemsToShow, setitemsToShow] = useState(items);

  const toggleClass = (index, key) => {
    setIsActive(index);
    if (key === "All") {
      setitemsToShow(items);
    } else {
      const filterId = filters[key][0].facetValue.facet.id;
      const showItems = items.filter((item) =>
        item.facetIds.includes(filterId)
      );
      setitemsToShow(showItems);
    }
  };

  const title =
    keys.length > 2 ? (
      <div className="SPP">
        {keys.map((key, i) => (
          <span
            className={`SPP__title pointer ${isActive === i ? "active" : ""}`}
            onClick={() => toggleClass(i, key)}
            key={i}
          >
            {key}
          </span>
        ))}
      </div>
    ) : (
      ""
    );
  return (
    <div className="SPP__container">
      {title}
      <Grid
        container
        spacing={window.innerWidth > 900 ? 2 : 1}
        justify="center"
      >
        {itemsToShow?.map((item, i) => (
          <Grid item key={i}>
            <ItemCard
              itemCard={item}
              key={i}
              id={item.productVariantId}
              title={item?.merchantName}
              img={item?.productAsset?.preview}
              productVariantName={item?.productVariantName}
              description={item?.productName}
              weight={item?.productVariantPriceMeasurement}
              merchantLogo={item?.merchantAsset?.preview}
              price={item?.priceWithTax.min || item?.priceWithTax?.value / 100}
              currencyCode={item?.currencyCode}
              showItem={() => {
                showItem(item.slug);
              }}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default SupplierPageProducts;
