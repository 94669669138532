import moment from 'moment';

export const formatDeliveryDate = (
  isoDate: string,
  startTime: string,
  endTime: string
): string => {
  // Parse the delivery date as UTC
  const deliveryDate = moment.utc(isoDate);

  // Create moments for today and tomorrow in UTC
  const today = moment.utc().startOf('day');
  const tomorrow = moment.utc().add(1, 'days').startOf('day');

  // Check if the delivery date is today
  if (deliveryDate.isSame(today, 'day')) {
    return `Next available delivery is today from ${startTime} to ${endTime}`;
  }

  // Check if the delivery date is tomorrow
  else if (deliveryDate.isSame(tomorrow, 'day')) {
    return `Next available delivery is tomorrow from ${startTime} to ${endTime}`;
  }

  // Format for other dates
  else {
    const formattedDate = deliveryDate.format('MMM D');
    return `Delivery on ${formattedDate} from ${startTime} to ${endTime}`;
  }
};
