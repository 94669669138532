import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router";
import Grid from "@material-ui/core/Grid";

import { GET_COLLECTIONS } from "../../../queries/queries";
import ItemCard from "../../common/item-card/item-card";
import Loader from "../../common/loader/loader.component";
import SortDropdown from "../../../shared/components/sort-dropdown/sort-dropdown";
import { FilterContext } from "../../../context/filterContext";

const CatalogItemsFiltered = ({ collectionId, facetValueIds, items, term }) => {
  const history = useHistory();
  const location = useLocation();

  const { loading, error, data } = useQuery(GET_COLLECTIONS);
  const { searchState, setSearchState } = useContext(FilterContext);

  if (loading) return <Loader color={"#B73232"} size={70} />;
  if (error) return `Error! ${error.message}`;

  const showItem = (x) => {
    history.push(`/item/${x}`);
  };

  let collectionName = collectionId?.name;
  let count = collectionId?.count;

  if (facetValueIds[0]) {
    collectionName = facetValueIds[0]?.name;
    count = facetValueIds[0]?.count;
  }

  let headerDisplay = (
    <>
      <span className="h2">{collectionName}</span>
      <span className="h5-regular" style={{ opacity: 0.64 }}>
        {items?.length} Results
      </span>
    </>
  );

  if (term?.trim()) {
    headerDisplay = (
      <span className="h3">
        {items?.length} results for "{term}"
      </span>
    );
  }

  const onSort = (sort) => {
    setSearchState({
      ...searchState,
      term: "",
      sort,
      previousPage: location?.pathname,
    });
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", marginBottom: 24 }}>
        <div
          style={{ display: "flex", flexDirection: "column", gap: 8, flex: 1 }}
        >
          {headerDisplay}
        </div>
        {!term?.trim() ? <SortDropdown onSort={onSort} /> : null}
      </div>
      <Grid container spacing={window.innerWidth > 900 ? 2 : 1}>
        {items?.map((item, i) => {
          const price =
            item?.priceWithTax?.min ||
            (item?.priceWithTax?.value / 100).toFixed(2);
          return (
            <Grid item>
              <ItemCard
                itemCard={item}
                id={item.productVariantId}
                productVariantName={item.productVariantName}
                key={i}
                title={item?.merchantName}
                img={item?.productAsset?.preview}
                description={item?.productName}
                weight={item?.productVariantPriceMeasurement}
                price={price}
                currencyCode={item?.currencyCode}
                merchantLogo={item?.merchantAsset?.preview}
                showItem={() => {
                  showItem(item?.slug);
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </React.Fragment>
  );
};

export default CatalogItemsFiltered;
