import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import { h5Medium, h6Medium, h7Regular, pointer } from "../../shared/constants";
import FormInput from "../common/form-input/form-input.component";

import "./auth-login.component.css";
import {
  GET_ACTIVE_USER,
  LOGIN,
  REQUEST_PASSWORD_RESET,
} from "../../queries/auth.queries";
import { useHistory } from "react-router-dom";
import Loader from "../common/loader/loader.component";
import { isErrorLogin } from "./auth.util.js";
// import { validateEmail } from "../../utils/validate-email.util";
import { AccountContext } from "../../context/account.context";
import { GET_TOTAL_LINES } from "../../queries/cart.queries";
import { ErrorHandlingContext } from "../../context/error-handling.context";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  container: {
    height: 150,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  inputField: {
    width: "100%",
  },
  button: {
    height: 56,
    width: "100%",
    border: "none",
    textAlign: "center",
    background: "#80BB34",
    borderRadius: 16,
    marginTop: 24,
    ...h5Medium,
    color: "#FFFFFF",
    ...pointer,
  },
}));

const AuthLogin = ({ handleClose, route = "/catalog" }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const { accountState, setAccountState } = useContext(AccountContext);
  const { setErrorHandlingState } = useContext(ErrorHandlingContext);
  const [showPassword, setShowPassword] = useState(false);

  const [userCredentials, setCredentials] = useState({
    email: "",
    password: "",
    emailForPassword: "",
  });

  const [forgotMode, setForgotMode] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const [erros, setErros] = useState({
    emailMessage: "",
    passwordMessage: "",
  });

  const [errorMsg, setErrorMsg] = useState({
    isError: false,
    errorMessage: "",
  });

  const { emailMessage, passwordMessage } = erros;

  const { email, password, emailForPassword } = userCredentials;

  const { isError, errorMessage } = errorMsg;

  let [login, { loading, data }] = useMutation(LOGIN, {
    variables: {
      username: email,
      password: password,
      rememberMe: false,
    },
    refetchQueries: [
      { query: GET_ACTIVE_USER }, // Query name
      { query: GET_TOTAL_LINES },
    ],
    awaitRefetchQueries: true,
  });

  let [requestReset] = useMutation(REQUEST_PASSWORD_RESET, {
    variables: {
      emailAddress: emailForPassword,
    },
  });

  const redirectUser = () => {
    history.push(route);
    const isCheckoutPage = window.location.href.includes("checkout");
    if (isCheckoutPage) {
      setAccountState({
        ...accountState,
        isUser: true,
      });

      return
    };

    window.location.reload();
  };

  if (loading) return <Loader color={"#B73232"} size={70} />;

  if (data) {
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorMsg({
      isError: false,
      errorMessage: "",
    });

    const { value, name } = e.target;

    if (value && !value.length) {
      setErros({
        ...erros,
        [`${name}Message`]: `${name} is required`,
        [`${name}Error`]: true,
      });
      return;
    }
    // if (!validateEmail(email)) {
    //   setErros({
    //     ...erros,
    //     emailError: true,
    //     emailMessage: "Inalid email address",
    //   });
    //   return;
    // }

    try {
      const res = await login();
      if (isErrorLogin(res.data?.login.__typename)) {
        setErrorMsg({
          isError: true,
          errorMessage: res.data?.login.message,
        });
        setErrorHandlingState({
          showError: true,
          data: res.data?.login.message,
        });
        return;
      }

      setAccountState({
        ...accountState,
        isUser: true,
      });

      handleClose();
      redirectUser();
    } catch (e) {
      console.error(e);
    }
  };

  const handleForgotSubmit = async (e) => {
    e.preventDefault();
    const data = await requestReset();

    if (data && data?.data?.requestPasswordReset?.success) {
      setEmailSent(true);
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;

    setCredentials({ ...userCredentials, [name]: value });

    if (!value || !value.length) {
      setErros({
        ...erros,
        [`${name}Message`]: `${name} is required`,
        [`${name}Error`]: true,
      });
    } else {
      setErros({
        ...erros,
        [`${name}Message`]: "",
        [`${name}Error`]: false,
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const form = (
    <form onSubmit={handleSubmit}>
      <FormInput
        name="email"
        type="string"
        value={userCredentials.email}
        handleChange={handleChange}
        label={t("auth.Email")}
        message={emailMessage}
        required
      />
      <div style={{ position: "relative" }}>
        <FormInput
          name="password"
          type={showPassword ? "text" : "password"}
          value={userCredentials.password}
          label={t("auth.Password")}
          handleChange={handleChange}
          message={passwordMessage}
          margin="45px 0 0 0"
          required
        />
        <span
          onClick={togglePasswordVisibility}
          style={{
            position: "absolute",
            right: 12,
            top: 10,
            cursor: pointer,
            color: "#15222F",
            opacity: 0.8,
          }}
        >
          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </span>
      </div>
      <span
        style={{
          display: "flex",
          justifyContent: "flex-end",
          ...h7Regular,
          marginTop: 4,
          color: "#80BB34",
          ...pointer,
        }}
        onClick={() => {
          setForgotMode(true);
        }}
      >
        {t("auth.forgot")}
      </span>
      <div className="buttons" style={{ marginTop: 44 }}>
        <button type="submit" className={classes.button}>
          {t("auth.sign in")}
        </button>
      </div>
      <pre>
        {isError ? (
          <span className="AL__errors h6-medium">{errorMessage}</span>
        ) : (
          ""
        )}
      </pre>
    </form>
  );

  const forgotForm = emailSent ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: 35,
        ...h6Medium,
        color: "#80BB34",
      }}
    >
      {t("auth.requestedEmailSent")}
    </div>
  ) : (
    <form onSubmit={handleForgotSubmit}>
      <FormInput
        name="emailForPassword"
        type="string"
        value={userCredentials.emailForPassword}
        handleChange={handleChange}
        label={t("auth.Email")}
        message={emailMessage}
        required
      />
      <div className="buttons" style={{ marginTop: 44 }}>
        <button type="submit" className={classes.button}>
          {t("auth.resetPassword")}
        </button>
      </div>
      <pre>
        {isError ? (
          <span className="AL__errors h6-medium">{errorMessage}</span>
        ) : (
          ""
        )}
      </pre>
    </form>
  );
  return <React.Fragment>{forgotMode ? forgotForm : form}</React.Fragment>;
};

export default AuthLogin;
