import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import Slider from "react-slick";
import { settings } from "./item-page-also-like.helper";

import { GET_ITEMS_WITH_FILTERS } from "../../../queries/queries";

import ItemCard from "../../common/item-card/item-card";

import "./item-page-also-like.component.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useLocationFromSession from "../../../shared/hooks/useLocationFromSession";

import { useTranslation } from "react-i18next";

export const ItemPageAlsoLike = ({ data }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [collectionIds, setCollectionIds] = useState([]);

  const { queryLocation, isLocationValid } = useLocationFromSession();
  const [loadLookAlikeItems, { called, loading, data: lookAlikeItems }] =
    useLazyQuery(GET_ITEMS_WITH_FILTERS, {
      // skip: !isLocationValid,
      variables: {
        collectionId: collectionIds,
        location: isLocationValid ? queryLocation : null,
      },
    });

  useEffect(() => {
    const collectionsData = data?.product?.collections?.map((col) => col?.id);
    if (collectionsData?.length) {
      setCollectionIds(collectionsData[0]);
      loadLookAlikeItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  if (called && loading) return <p>Loading ...</p>;

  // const newArray = [];
  // let tempArray = [];

  // lookAlikeItems?.search?.items?.map((s) => {
  //   tempArray.push(s);
  //   if (tempArray.length === 2) {
  //     newArray.push(tempArray);
  //     tempArray = [];
  //   }
  //   return s;
  // });

  const showItem = (x) => {
    history.push(`/item/${x.slug}`);
  };

  return (
    <div className="item-details__look-alike">
      {lookAlikeItems?.search?.items?.length ? (
        <React.Fragment>
          <h3 className="item-details__look-alike--item h3">
            {t("itemPage.youMayAlsoLike")}
          </h3>
          {/* <Slider {...settings}> */}
          <div style={{ display: "flex", gap: 16, overflow: "auto" }}>
            {lookAlikeItems?.search?.items?.slice(0, 2).map((item) => (
              <div key={item?.productVariantId}>
                <ItemCard
                  itemCard={item}
                  id={item?.productVariantId}
                  productVariantName={item?.productVariantName}
                  title={item?.merchantName}
                  img={item?.productAsset?.preview}
                  description={item?.productName}
                  weight={item?.customFields?.priceMeasurement}
                  price={item?.priceWithTax?.value}
                  merchantLogo={item?.merchantAsset?.preview}
                  showItem={() => showItem(item)}
                />
              </div>
            ))}
          </div>
          {/* </Slider> */}
        </React.Fragment>
      ) : (
        ""
      )}
    </div>
  );
};

export default ItemPageAlsoLike;
