import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Radio from "@mui/material/Radio";
import { h5Medium, h6Regular } from "../../../shared/constants";
import useFormatedDate from "./useNextDayDate";
import { isToday } from "date-fns";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  deliveryCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: 150,
  },
  title: {
    ...h5Medium,
    display: "flex",
    marginBottom: 18,
  },
  hour: {
    display: "flex",
    ...h6Regular,
  },
});

const DeliveryScheduler = ({ timesAvailable, onHourPicked, date = null }) => {
  const classes = useStyles();
  const { getFormattedDate } = useFormatedDate();

  const [selectedValue, setSelectedValue] = useState("1");
  const [updatedDate, setUpdatedDate] = useState(date);

  useEffect(() => setUpdatedDate(date), [date]);

  const controlProps = (id) => ({
    checked: selectedValue === id,
    onChange: () => {
      setSelectedValue(id);
      onHourPicked(id);
    },
    value: id,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": id },
  });

  // Get the current time for disabling past hours
  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  const currentDate = new Date(updatedDate);

  return (
    <div className={classes.container}>
      <div className={classes.deliveryCol}>
        {/* TEMP REMOVE */}
        {/* <span className={classes.title}>
          {updatedDate ? getFormattedDate(updatedDate) : null}
        </span> */}
        {timesAvailable?.map((o, i) => {
          // Disable option if it's today and the hour has passed
          const isDisabled = isToday(currentDate) && o.hour < currentHour; // Assuming `o.hour` is available and contains the hour of the option

          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
              key={i}
            >
              <div
                style={{
                  height: 35,
                  width: 55,
                }}
              >
                <Radio
                  {...controlProps(`${o.id}`)}
                  color="success"
                  disabled={isDisabled}
                />
              </div>
              <div className={classes.hour}>{o.description}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DeliveryScheduler;
