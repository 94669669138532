/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

const GET_DELIVERY_DATE = gql`
  query ($productVariantId: ID!, $location: LocationInput!) {
    deliveryDate(productVariantId: $productVariantId, location: $location) {
      deliveryDate
      deliveryDelayed
    }
  }
`;

export { GET_DELIVERY_DATE };
