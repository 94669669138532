import React, { useState, useEffect, useContext } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Backdrop, Fade, Modal, makeStyles } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import { isValidPhoneNumber } from "react-phone-number-input";
import DeliveryNotes from "../../../shared/components/delivery-notes/delivery-notes.component.jsx";
import {
  h5Medium,
  h6Medium,
  h6Regular,
  pointer,
} from "../../../shared/constants";
import { h3 } from "../../../shared/constants";
import { ReactComponent as DeliveryTruckIcon } from "../../../assets/deliveryTruck.svg";

import { CREATE_ADDRESS, GET_ACTIVE_USER } from "../../../queries/auth.queries";

import { SET_SHIPPING_ORDER } from "../../../queries/checkout.queries";
import { useTranslation } from "react-i18next";
import VerifyNumberContainer from "../../common/verify-number/verify-number-container.component";
import AddressForm from "../../account/add-address/address-form.component";
import SavedAddresess from "./saved-addreses.component";
import DefaultAddress from "./default-address.component";
import { UPDATE_ADDRESS } from "../../../queries/user.queries";
import Loader from "../../common/loader/loader.component";
import { ErrorHandlingContext } from "../../../context/error-handling.context";
// import { ADD_DELIVERY_NOTE } from "../../../mutations/delivery-notes.mutation.js";
import { GET_ACTIVE_ORDER } from "../../../queries/cart.queries";

const useStyles = makeStyles({
  bodyAddresses: {
    overflow: "auto",
  },
  bodyTitles: {
    display: "flex",
    marginBottom: 32,

    "@media (max-width:940px)": {
      marginBottom: 21,
    },
  },

  button: {
    height: 56,
    width: 240,
    border: "none",
    textAlign: "center",
    borderRadius: 16,
    marginTop: 24,
    ...h5Medium,
    color: "#FFFFFF",
    ...pointer,
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const AddressProcess = ({ setProcesses, processes, onEditAddress }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const defaultShipping = t("checkout.Default");
  const savedShipping = t("checkout.Saved");
  const addNewShipping = t("checkout.Add New");

  const { setErrorHandlingState } = useContext(ErrorHandlingContext);

  const { data: userData } = useQuery(GET_ACTIVE_USER);
  const { data: orderData } = useQuery(GET_ACTIVE_ORDER);

  const { stage2UserDetails, stage3DeliverTo } = processes;

  // use states
  const [closeAccordion, setCloseAccordion] = useState(false);
  const [isValidInput, setIsVaildInput] = useState(false);
  const [isValidContinue, setIsVaildContinue] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentNav, setCurrentNav] = useState(
    userData?.activeCustomer?.addresses?.length > 0
      ? t("checkout.Default")
      : t("checkout.Add New")
  );
  const [navState, setNavState] = useState({
    [`${savedShipping}`]: false,
    [`${defaultShipping}`]:
      userData?.activeCustomer?.addresses?.length > 0 ? true : false,
    [`${addNewShipping}`]:
      userData?.activeCustomer?.addresses?.length > 0 ? false : true,
  });
  const [addressForShipping, setAddressForShipping] = useState({
    fullName: "",
    streetLine1: "",
    streetLine2: "",
    city: "",
    postalCode: "",
    countryCode: "IL",
    phoneNumber: "",
    defaultShippingAddress: false,
    defaultBillingAddress: false,
    idForUpdate: null,
  });

  const currentOrderAddress = orderData?.activeOrder?.shippingAddress;

  const [deliveryNote, setDeliveryNote] = useState("");

  const [isFirstAddress, setIsFirstAddress] = useState(true);

  useEffect(() => {
    if (userData) {
      const userAddresses = userData?.activeCustomer?.addresses;

      if (userAddresses?.length >= 1) {
        setIsFirstAddress(false);
        setCurrentNav(defaultShipping);
        setNavState(defaultShipping);
      }
    }
  }, [userData]);

  let [
    setShippingAddress,
    { loading: setOrderShippingLoader, error: setOrderShippingError },
  ] = useMutation(SET_SHIPPING_ORDER, {
    variables: {
      fullName: addressForShipping.fullName,
      streetLine1: addressForShipping.streetLine1,
      streetLine2: addressForShipping.streetLine2,
      city: addressForShipping.city,
      postalCode: addressForShipping.postalCode,
      countryCode: addressForShipping.countryCode,
      phoneNumber: addressForShipping.phoneNumber,
      defaultShippingAddress:
        isFirstAddress || addressForShipping.defaultShippingAddress,
      defaultBillingAddress:
        isFirstAddress || addressForShipping.defaultBillingAddress,
    },
    refetchQueries: [{ query: GET_ACTIVE_USER }, { query: GET_ACTIVE_ORDER }],
    awaitRefetchQueries: true,
    onCompleted: async (data) => {
      if (data?.setOrderShippingAddress?.message) {
        setErrorHandlingState({
          showError: true,
          data: data?.setOrderShippingAddress?.message,
        });
        return;
      }
      // await addDeliveryNote();
      prepareMovingToNextStage();
    },
  });

  let [createAddress, { loading: createAddressLoading }] =
    useMutation(CREATE_ADDRESS);

  let [updateAddress, { loading: updateAddressLoader }] = useMutation(
    UPDATE_ADDRESS,
    {
      variables: {
        id: addressForShipping?.idForUpdate,
        defaultShippingAddress: addressForShipping.defaultShippingAddress,
      },
      refetchQueries: [
        { query: GET_ACTIVE_USER }, // Query name
      ],
      awaitRefetchQueries: true,
      onCompleted: async (data) => {
        if (data?.updateCustomerAddress?.errorCode) {
          setErrorHandlingState({
            showError: true,
            data: data?.updateCustomerAddress?.message,
          });
          return;
        }
        await setShippingAddressCall();
      },
    }
  );

  // let [addDeliveryNote, { loading: addDeliveryNoteLoader }] = useMutation(
  //   ADD_DELIVERY_NOTE,
  //   {
  //     variables: {
  //       deliveryNotes: deliveryNote
  //     },
  //     refetchQueries: [
  //       { query: GET_ACTIVE_USER }, // Query name
  //     ],
  //     awaitRefetchQueries: true,
  //     onCompleted: async (data) => {
  //       if (data?.addDeliveryNoteToOrder?.errorCode) {
  //         setErrorHandlingState({
  //           showError: true,
  //           data: data?.addDeliveryNoteToOrder?.message,
  //         });
  //         return;
  //       }
  //       prepareMovingToNextStage();
  //     },
  //   }
  // );

  const setShippingAddressCall = async () => {
    try {
      await setShippingAddress();
    } catch (err) {
      setErrorHandlingState({
        showError: true,
        data: "An internal error has occurred",
      });
    }
  };

  const setAndCreateAddressCall = async () => {
    try {
      const { data } = await setShippingAddress();

      // Proceed only if no message is returned
      if (!data?.setOrderShippingAddress?.message) {
        const {
          fullName,
          streetLine1,
          streetLine2,
          city,
          postalCode,
          countryCode,
          phoneNumber,
          defaultShippingAddress,
          defaultBillingAddress,
        } = addressForShipping;

        const addressResponse = await createAddress({
          variables: {
            fullName,
            streetLine1,
            streetLine2,
            city,
            postalCode,
            countryCode,
            phoneNumber,
            defaultShippingAddress: isFirstAddress || defaultShippingAddress,
            defaultBillingAddress: isFirstAddress || defaultBillingAddress,
          },
          refetchQueries: [{ query: GET_ACTIVE_USER }],
          awaitRefetchQueries: true,
        });

        // Handle any errors within `onCompleted`
        if (addressResponse?.data?.createCustomerAddress?.errorCode) {
          setErrorHandlingState({
            showError: true,
            data:
              addressResponse.data.createCustomerAddress.message ||
              "Address creation failed",
          });
        }
      }
    } catch (error) {
      setErrorHandlingState({
        showError: true,
        data: "An internal error has occurred",
      });
    }
  };

  useEffect(() => {
    setCloseAccordion(stage2UserDetails);
  }, [stage2UserDetails]);

  useEffect(() => {
    if (
      (currentNav === `${defaultShipping}` &&
        userData?.activeCustomer?.addresses?.length > 0) ||
      (currentNav === `${savedShipping}` &&
        userData?.activeCustomer?.addresses?.length > 0) ||
      (currentNav === `${addNewShipping}` &&
        addressForShipping.streetLine1 !== "" &&
        addressForShipping.phoneNumber !== "" &&
        isValidInput &&
        isValidPhoneNumber(`${addressForShipping.phoneNumber}`))
    ) {
      setIsVaildContinue(true);
    } else {
      setIsVaildContinue(false);
    }
  }, [
    addressForShipping.phoneNumber,
    addressForShipping.streetLine1,
    currentNav,
    userData?.activeCustomer?.addresses?.length,
    isValidInput,
    defaultShipping,
    savedShipping,
    addNewShipping,
  ]);

  const onNavClicked = (e) => {
    const { innerText } = e.target;

    setNavState({
      Default: false,
      Saved: false,
      "Add New": false,
      [innerText]: true,
    });

    setCurrentNav(innerText);
  };

  const prepareMovingToNextStage = () => {
    setCurrentNav("Default");
    setCloseAccordion(false);
    setProcesses();
    setNavState({
      [`${defaultShipping}`]: true,
      [`${savedShipping}`]: false,
      [`${addNewShipping}`]: false,
    });
  };

  const onContinue = async () => {
    if (
      (currentNav === `${defaultShipping}` &&
        userData?.activeCustomer?.addresses?.length > 0) ||
      (currentNav === `${savedShipping}` &&
        userData?.activeCustomer?.addresses?.length > 0)
    ) {
      try {
        await updateAddress();
      } catch (err) {
        setErrorHandlingState({
          showError: true,
          data: "An internal error has occurred",
        });
      }
      return;
    }

    if (
      currentNav === `${addNewShipping}` &&
      addressForShipping.streetLine1 !== "" &&
      addressForShipping.phoneNumber !== ""
    ) {
      // Temp until we have support for phone validation
      // if (
      //   addressForShipping.phoneNumber !== userData?.activeCustomer?.phoneNumber
      // ) {
      //   setOpen(true);
      // } else {
      onVerify();
      // }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onVerify = async (bool = true) => {
    // Temp until we have support for phone validation
    // if (!bool) return;
    // setOpen(false);

    try {
      await setAndCreateAddressCall();
    } catch (err) {
      setErrorHandlingState({
        showError: true,
        data: "An internal error has occurred",
      });
    }
  };

  const savedAddresess = (
    <SavedAddresess setAddressForShipping={setAddressForShipping} />
  );

  const defaultAddressLine = (
    <DefaultAddress setAddressForShipping={setAddressForShipping} />
  );

  const renderSwitch = (param) => {
    switch (param) {
      case t("checkout.Default"):
        return defaultAddressLine;
      case t("checkout.Add New"):
        return (
          <div>
            {createAddressLoading ? (
              <Loader color={"#B73232"} size={50} />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "660px",
                }}
              >
                <AddressForm
                  addressDetails={addressForShipping}
                  setAddressDetails={setAddressForShipping}
                  setIsVaildInput={setIsVaildInput}
                  isFirstAddress={isFirstAddress}
                />
              </div>
            )}
          </div>
        );
      case t("checkout.Saved"):
        return savedAddresess;
      default:
        return defaultAddressLine;
    }
  };

  return (
    <React.Fragment>
      <Accordion
        style={{
          borderRadius: "16px",
          padding: 30,
          boxShadow: "0px 16px 32px rgba(29, 38, 102, 0.08)",
          marginBottom: 24,
          borderBottom: "none",
        }}
        expanded={closeAccordion}
        disabled={!stage2UserDetails}
      >
        <AccordionSummary>
          <Typography style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <DeliveryTruckIcon
                  style={{
                    marginRight: t("css.side") !== "ltr" ? 0 : 16,
                    marginLeft: t("css.side") !== "ltr" ? 16 : 0,
                  }}
                />
                <span
                  style={{
                    ...h3,
                    fontFamily:
                      t("css.side") !== "ltr"
                        ? "Assistant"
                        : "DM Serif Display",
                  }}
                >
                  {t("account.deliveryTo")}
                </span>
              </div>
              {stage3DeliverTo ? (
                <span
                  style={{
                    ...h6Medium,
                    color: "#80BB34",
                  }}
                  onClick={() => {
                    setCloseAccordion(true);
                    onEditAddress();
                  }}
                >
                  {t("common.edit")}
                </span>
              ) : (
                ""
              )}
            </div>
            {!closeAccordion && (
              <div
                style={{
                  ...h6Regular,
                  opacity: 0.8,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {currentOrderAddress?.streetLine1 && (
                  <>
                    <span style={{ ...h6Regular, opacity: 0.8 }}>
                      {currentOrderAddress?.streetLine1},{" "}
                      {currentOrderAddress?.city},
                      {currentOrderAddress?.postalCode ??
                        `${currentOrderAddress?.postalCode}, `}
                      {currentOrderAddress?.countryCode}
                    </span>
                    <span style={{ ...h6Regular, opacity: 0.8 }}>
                      {currentOrderAddress?.phoneNumber}
                    </span>
                  </>
                )}
              </div>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className={classes.bodyTitles}>
              {[
                `${t("checkout.Default")}`,
                `${t("checkout.Saved")}`,
                `${t("checkout.Add New")}`,
              ].map((tabName, index) => (
                <span
                  key={index}
                  style={{
                    ...h5Medium,
                    marginRight: 32,
                    ...pointer,
                    opacity: navState[tabName] === true ? 1 : 0.64,
                  }}
                  onClick={(e) => onNavClicked(e)}
                >
                  {tabName}
                </span>
              ))}
            </div>
            {renderSwitch(currentNav)}
            <div style={{ width: "50%" }}>
              <DeliveryNotes onTextChange={(text) => console.log(text)} />
            </div>
            <button
              className={classes.button}
              style={{
                background: isValidContinue ? "#80BB34" : "lightgray",
              }}
              onClick={() => {
                onContinue();
              }}
              disabled={!isValidContinue}
            >
              {setOrderShippingLoader ||
                updateAddressLoader ||
                createAddressLoading ? (
                <Loader color={"#B73232"} size={30} />
              ) : (
                t("common.Continue")
              )}
            </button>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <VerifyNumberContainer
            handleClose={handleClose}
            onVerify={onVerify}
            phoneNumber={addressForShipping.phoneNumber}
            setPhoneValue={(e) => {
              setAddressForShipping({
                ...addressForShipping,
                phoneNumber: e,
              });
            }}
          />
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

export default AddressProcess;
